import React from "react"
import { graphql } from "gatsby"

import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

interface Props {
    data: {
        site: {
            siteMetadata: {
                title: string
            }
        }
    },
    location: Location
}

const OmPage = ({ data, location }: Props) => {
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="Om Minimalisera" />

            <div className="max-w-2xl mx-auto">
                <h1 className="text-4xl leading-tight m-0">Om Minimalisera</h1>
                <section className="py-2">
                    <p>
                        Denna sidan drivs av mig, Nicklas Jarnesjö. 2019 var året jag verkligen tog tag i den där känsla över att det var något som fattades och gjorde att jag inte
                        riktigt fann ro i vardagen. Det kändes som jag hade så mycket jag ville göra, men aldrig hann med och ofta kände mig otillräcklig.
                        Jag tröttnade även på mitt köpbeteende som kom i skov där jag köpte ofta meningslösa saker och det var då av en slump jag fann stötte begreppet minimalism.
                    </p>
                    <p>
                        Efter ha studerat ämnet närmare och djupare så började jag känna att detta var något som jag skulle vilja praktisera och ha som verktyg för att få
                        ett lugnare och mer meningsfullt liv. Något att luta sig emot och använda som verktyg påvägen framåt. Mitt beteende har ofta stört mig genom att köpa
                        meningslösa saker bara för att jag kan och är privilegad. Vad fyller det för funktion egentligen mer än väldigt tillfällig tillfredställes?
                    </p>
                    <p>
                        Följde blev att jag började göra mig av med saker - sälja, skänka och slänga. Detta fick mig att känna en frihetskänsla och lättnad. Det är bara en början
                        men nu vill jag dela med mig av erfarenheter och insikter när jag försöker applicera detta på fler saker framöver.
                    </p>
                </section>
            </div>
        </Layout>
    )
}

export default OmPage

export const pageQuery = graphql`
  query AboutQuery{
    site {
      siteMetadata {
        title
      }
    }
  }
`